import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import Contact from './views/contact'
import Portfolio from './views/portfolio'
import About from './views/about'
import Home from './views/home'
import Services from './views/services'
import NotFound from './views/not-found'
import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'Contact',
      path: '/contact',
      component: Contact,
    },
    {
      name: 'Portfolio',
      path: '/portfolio',
      component: Portfolio,
    },
    {
      name: 'About',
      path: '/about',
      component: About,
    },
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
    {
      name: 'Services',
      path: '/services',
      component: Services,
    },
    {
      name: '404 - Not Found',
      path: '**',
      component: NotFound,
      fallback: true,
    },
  ],
})
