<template>
  <div class="contact-container"
    ><div class="contact-header"
      ><header
        data-thq="thq-navbar"
        class="navbarContainer contact-navbar-interactive"
        ><span class="logo">KLINGSPOR Engineering</span
        ><div data-thq="thq-navbar-nav" class="contact-desktop-menu"
          ><nav class="contact-links"></nav
          ><div class="contact-buttons"></div></div
        ><div data-thq="thq-burger-menu" class="contact-burger-menu"
          ><svg viewBox="0 0 1024 1024" class="contact-icon socialIcons">
            <path
              d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
            ></path></svg></div
        ><div data-thq="thq-mobile-menu" class="contact-mobile-menu1 mobileMenu"
          ><div class="contact-nav"
            ><div class="contact-top"
              ><span class="logo">SAFETYENG</span
              ><div data-thq="thq-close-menu" class="contact-close-menu"
                ><svg viewBox="0 0 1024 1024" class="contact-icon02 socialIcons">
                  <path
                    d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                  ></path></svg></div></div
            ><nav class="contact-links1"
              ><span class="contact-nav12 bodySmall">Home</span
              ><span class="contact-nav22 bodySmall">About</span
              ><span class="contact-nav32 bodySmall">Services</span
              ><span class="contact-nav42 bodySmall">Portfolio</span
              ><span class="contact-nav52 bodySmall">Contact</span></nav
            ><div class="contact-buttons1"
              ><button class="buttonFlat">Login</button
              ><button class="buttonFilled">Register</button></div
            ></div
          ><div
            ><svg
              viewBox="0 0 950.8571428571428 1024"
              class="contact-icon04 socialIcons"
            >
              <path
                d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
              ></path></svg
            ><svg
              viewBox="0 0 877.7142857142857 1024"
              class="contact-icon06 socialIcons"
            >
              <path
                d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
              ></path></svg
            ><svg
              viewBox="0 0 602.2582857142856 1024"
              class="contact-icon08 socialIcons"
            >
              <path
                d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
              ></path></svg></div></div></header></div
    ><div class="contact-hero"
      ><div class="heroContainer contact-hero1"
        ><h1 class="contact-hero-heading"
          ><span class="heading1 contact-text">Contact request</span
          ><br /><br /></h1
        ><div class="contact-container1"
          ><form
            name="contact"     
            method="POST"                 
            class="contact-form"
            netlify-honeypot="bot-field"
            netlify
            >
            <input type="hidden" name="form-name" value="contact" />
            <input
              type="text"
              name="Name"
              required="true"
              placeholder="name"
              autocomplete="name"
              class="contact-textinput input"
            /><input
              type="email"
              name="email"
              pattern="+@+"
              required="true"
              placeholder="email@"
              autocomplete="email"
              class="contact-textinput1 input"
            /><input
              type="tel"
              name="phone"
              required="true"
              placeholder="+49"
              autocomplete="tel"
              class="contact-textinput2 input"
            /><textarea
              name="Message"
              placeholder="Message"
              class="contact-textarea textarea"
            ></textarea
            ><button type="submit" class="contact-hero-button1 buttonFilled"
              >Send request</button
            ></form
          ><span class="contact-hero-sub-heading bodyLarge"
            ><span
              ><span
                ><span>Your Partner for Functional Safety Solutions</span
                ><span> <span v-html="rawky7p"></span></span></span
              ><span
                ><span> <span v-html="raw5fi0"></span></span
                ><span> <span v-html="raw8nm9"></span></span></span></span
            ><span
              ><span
                ><span> <span v-html="rawu3ga"></span></span
                ><span> <span v-html="rawrmej"></span></span></span
              ><span
                ><span> <span v-html="rawzxyh"></span></span
                ><span> <span v-html="rawiiue"></span></span></span></span></span
          ><div class="contact-btn-group"></div></div></div></div
    ><div class="contact-footer"
      ><footer class="footerContainer contact-footer1"
        ><div class="contact-container2"
          ><span class="logo">KLINGSPOR ENGINEERING</span
          ><nav class="contact-nav1"
            ><router-link to="/" class="contact-nav121 bodySmall"
              >Home</router-link
            ><router-link to="/about" class="contact-nav221 bodySmall"
              >About</router-link
            ><router-link to="/services" class="contact-nav321 bodySmall"
              >Services</router-link
            ><router-link to="/portfolio" class="contact-nav421 bodySmall"
              >Portfolio</router-link
            ><router-link to="/contact" class="contact-nav521 bodySmall"
              >Contact</router-link
            ></nav
          ></div
        ><div class="contact-separator"></div
        ><div class="contact-container3"
          ><span class="bodySmall contact-text17"
            >© 2024 Klingspor Engineering, All Rights Reserved.</span
          ><div class="contact-icon-group1"
            ><svg
              viewBox="0 0 950.8571428571428 1024"
              class="contact-icon10 socialIcons"
            >
              <path
                d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
              ></path></svg
            ><a
              href="https://www.linkedin.com/in/alexander-klingspor/"
              target="_blank"
              rel="noreferrer noopener"
              class="contact-link"
              ><svg viewBox="0 0 1024 1024" class="contact-icon12">
                <path
                  d="M928 0h-832c-52.8 0-96 43.2-96 96v832c0 52.8 43.2 96 96 96h832c52.8 0 96-43.2 96-96v-832c0-52.8-43.2-96-96-96zM384 832h-128v-448h128v448zM320 320c-35.4 0-64-28.6-64-64s28.6-64 64-64c35.4 0 64 28.6 64 64s-28.6 64-64 64zM832 832h-128v-256c0-35.4-28.6-64-64-64s-64 28.6-64 64v256h-128v-448h128v79.4c26.4-36.2 66.8-79.4 112-79.4 79.6 0 144 71.6 144 160v288z"
                ></path></svg></a></div></div></footer></div
  ></div>
</template>

<script>
export default {
  name: 'Contact',
  props: {},
  data() {
    return {
      rawky7p: ' ',
      raw5fi0: ' ',
      raw8nm9: ' ',
      rawu3ga: ' ',
      rawrmej: ' ',
      rawzxyh: ' ',
      rawiiue: ' ',
    }
  },
  metaInfo: {
    title: 'Contact - Klingspor Engineering',
    meta: [
      {
        name: 'description',
        content:
          'Description of offered engineering services in aerospace and automotive engineering. Charging, electric propulsion, battery system, aircraft certification',
      },
      {
        property: 'og:title',
        content: 'Contact - Klingspor Engineering',
      },
      {
        property: 'og:description',
        content:
          'Description of offered engineering services in aerospace and automotive engineering. Charging, electric propulsion, battery system, aircraft certification',
      },
    ],
  },
}
</script>

<style scoped>
.contact-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-custom-neutral-dark);
}
.contact-header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.contact-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.contact-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contact-buttons {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact-burger-menu {
  display: none;
}
.contact-icon {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.contact-mobile-menu1 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
}
.contact-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.contact-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-icon02 {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.contact-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.contact-nav12 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-nav22 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-nav32 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-nav42 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-nav52 {
  margin-bottom: var(--dl-space-space-unit);
}
.contact-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.contact-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.contact-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.contact-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 96px;
  align-items: flex-start;
  justify-content: center;
}
.contact-hero-heading {
  max-width: 800px;
  text-align: center;
}
.contact-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.contact-form {
  width: 1136px;
  height: 329px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.contact-textinput {
  width: var(--dl-size-size-xxlarge);
  height: 37px;
  align-self: center;
  text-align: left;
  margin-bottom: var(--dl-space-space-unit);
}
.contact-textinput1 {
  width: var(--dl-size-size-xxlarge);
  align-self: center;
  text-align: left;
  margin-bottom: var(--dl-space-space-unit);
}
.contact-textinput2 {
  width: var(--dl-size-size-xxlarge);
  align-self: center;
  text-align: left;
  margin-bottom: var(--dl-space-space-unit);
}
.contact-textarea {
  width: 717px;
  height: 207px;
  align-self: center;
  margin-bottom: var(--dl-space-space-unit);
}
.contact-hero-button1 {
  align-self: center;
}
.contact-hero-sub-heading {
  font-size: 18px;
  text-align: center;
}
.contact-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.contact-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-container2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.contact-nav1 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.contact-nav121 {
  text-decoration: none;
}
.contact-nav221 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.contact-nav321 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.contact-nav421 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.contact-nav521 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.contact-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.contact-container3 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.contact-icon10 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.contact-link {
  display: contents;
}
.contact-icon12 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .contact-hero1 {
    flex-direction: column;
  }
  .contact-hero-heading {
    text-align: center;
  }
  .contact-text {
    text-align: center;
  }
  .contact-container1 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .contact-hero-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .contact-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .contact-desktop-menu {
    display: none;
  }
  .contact-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contact-nav12 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-nav22 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-nav32 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-nav42 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-nav52 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .contact-hero-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .contact-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .contact-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .contact-container3 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .contact-text17 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .contact-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .contact-mobile-menu1 {
    padding: 16px;
  }
  .contact-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .contact-container1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .contact-btn-group {
    flex-direction: column;
  }
  .contact-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .contact-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .contact-container3 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .contact-text17 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
</style>
