<template>
  <div class="home-container"
    ><div class="home-header"
      ><header
        data-thq="thq-navbar"
        class="navbarContainer home-navbar-interactive"
        ><span class="logo">KLINGSPOR Engineering</span
        ><div data-thq="thq-navbar-nav" class="home-desktop-menu"
          ><nav class="home-links"></nav><div class="home-buttons"></div></div
        ><div data-thq="thq-burger-menu" class="home-burger-menu"
          ><svg viewBox="0 0 1024 1024" class="home-icon socialIcons">
            <path
              d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
            ></path></svg></div
        ><div data-thq="thq-mobile-menu" class="home-mobile-menu1 mobileMenu"
          ><div class="home-nav"
            ><div class="home-top"
              ><span class="logo">SAFETYENG</span
              ><div data-thq="thq-close-menu" class="home-close-menu"
                ><svg viewBox="0 0 1024 1024" class="home-icon02 socialIcons">
                  <path
                    d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                  ></path></svg></div></div
            ><nav class="home-links1"
              ><span class="home-nav12 bodySmall">Home</span
              ><span class="home-nav22 bodySmall">About</span
              ><span class="home-nav32 bodySmall">Services</span
              ><span class="home-nav42 bodySmall">Portfolio</span
              ><span class="home-nav52 bodySmall">Contact</span></nav
            ><div class="home-buttons1"
              ><button class="buttonFlat">Login</button
              ><button class="buttonFilled">Register</button></div
            ></div
          ><div
            ><svg
              viewBox="0 0 950.8571428571428 1024"
              class="home-icon04 socialIcons"
            >
              <path
                d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
              ></path></svg
            ><svg
              viewBox="0 0 877.7142857142857 1024"
              class="home-icon06 socialIcons"
            >
              <path
                d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
              ></path></svg
            ><svg
              viewBox="0 0 602.2582857142856 1024"
              class="home-icon08 socialIcons"
            >
              <path
                d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
              ></path></svg></div></div></header></div
    ><div class="home-hero"
      ><div class="heroContainer home-hero1"
        ><div class="home-container01"
          ><h1 class="home-hero-heading heading1"
            >Expert Aerospace and Automotive Engineering Services</h1
          ><span class="home-hero-sub-heading bodyLarge"
            ><span
              ><span
                ><span>Your Partner for Functional Safety Solutions</span
                ><span> <span v-html="rawi7ks"></span></span></span
              ><span
                ><span> <span v-html="raw54pw"></span></span
                ><span> <span v-html="rawxchh"></span></span></span></span
            ><span
              ><span
                ><span> <span v-html="raw00ws"></span></span
                ><span> <span v-html="rawmw01"></span></span></span
              ><span
                ><span> <span v-html="raw3zuq"></span></span
                ><span> <span v-html="rawz55g"></span></span></span></span></span
          ><div class="home-btn-group"
            ><router-link to="/contact" class="home-hero-button1 buttonFilled"
              >Contact Me</router-link
            ><router-link to="/portfolio" class="home-hero-button2 buttonFlat"
              >Learn More →</router-link
            ></div
          ></div
        ></div
      ></div
    ><div class="home-features"
      ><div class="featuresContainer"
        ><div class="home-features1"
          ><div class="home-container02"
            ><span class="overline"><span>Experience</span><br /></span
            ><h2 class="home-features-heading heading2">Proof of excellence</h2
            ><span class="home-features-sub-heading bodyLarge"
              ><span
                ><span
                  ><span
                    >Discover the unique strengths that set us apart in the
                    aerospace and automotive safety engineering field</span
                  ><span> <span v-html="rawhmod"></span></span></span
                ><span
                  ><span> <span v-html="rawj1fo"></span></span
                  ><span> <span v-html="rawkxdm"></span></span></span></span
              ><span
                ><span
                  ><span> <span v-html="rawjy2a"></span></span
                  ><span> <span v-html="raw9e9m"></span></span></span
                ><span
                  ><span> <span v-html="rawz6ch"></span></span
                  ><span>
                    <span
                      v-html="rawsalp"
                    ></span></span></span></span></span></div
          ><div class="home-container03"
            ><feature-card
              heading="Pioneering Work in eVTOL Technology"
              subHeading="Driving advancements in electric vertical take-off and landing aircraft with remarkable achievements"
            ></feature-card
            ><feature-card
              heading="Leadership in Wireless Charging Technology"
              subHeading="Built a world class team to innovate and implement wireless charging solutions for electric cars"
            ></feature-card
            ><feature-card
              heading="Extensive Experience in Functional Safety"
              subHeading="Specializing in ensuring safety and compliance for aerospace and automotive systems"
            ></feature-card
            ><feature-card
              heading="Expertise in New Product Introduction"
              subHeading="Proven track record of successfully launching new products in the aerospace and automotive industries"
            ></feature-card></div></div></div></div
    ><div class="home-offerings"
      ><div class="pricingContainer"
        ><div class="home-container04"
          ><span class="overline"><span>Support</span><br /></span
          ><h2 class="heading2">Services</h2
          ><span class="home-pricing-sub-heading bodyLarge"
            ><span
              ><span
                >Unlock the full potential of aerospace and automotive safety
                engineering with our tailored plans</span
              ></span
            ></span
          ></div
        ><div class="home-container05"
          ><div class="home-pricing-card freePricingCard"
            ><div class="home-container06"
              ><span class="home-text36 heading3">Engineering</span
              ><span class="bodySmall"
                >Support with the design of advcanced electrical high-power
                systems</span
              ></div
            ><div class="home-container07"
              ><span class="home-free-plan-price">Design</span></div
            ><div class="home-container08"
              ><div class="home-container09"
                ><span class="home-text37">✔</span
                ><span class="bodySmall">Architecture and concepts</span></div
              ><div class="home-container10"
                ><span class="home-text38">✔</span
                ><span>From requirement to certification</span></div
              ><div class="home-container11"
                ><span class="home-text39">✔</span
                ><span class="bodySmall">Electronics design</span></div
              ><div class="home-container12"
                ><span class="home-text40">✔</span
                ><span class="bodySmall"
                  >Troubleshoot the toughest issues</span
                ></div
              ><div class="home-container13"
                ><span class="home-text41">✔</span
                ><span>Validation &amp; Verification </span></div
              ><div class="home-container14"
                ><span class="home-text42">✔</span><span>DfX </span></div
              ></div
            ></div
          ><div class="home-pricing-card1 basicPricingCard"
            ><div class="home-container15"
              ><span class="home-text43 heading3">Product Development</span
              ><span
                >Prototype development of bespoke electronics and support in
                market launch</span
              ></div
            ><div class="home-container16"
              ><span class="home-basic-plan-pricing">Build</span></div
            ><div class="home-container17"
              ><div class="home-container18"
                ><span class="home-text44">✔</span
                ><span class="bodySmall"
                  >Prototypes and proof of concept</span
                ></div
              ><div class="home-container19"
                ><span class="home-text46">✔</span
                ><span class="bodySmall"
                  >Solve industrialization issues</span
                ></div
              ><div class="home-container20"
                ><span class="home-text47">✔</span
                ><span class="bodySmall">End of line testing</span></div
              ><div class="home-container21"
                ><span class="home-text48">✔</span
                ><span class="bodySmall">Series production preparation</span></div
              ><div class="home-container22"
                ><span class="home-text49">✔</span
                ><span>Get from concept to market</span></div
              ><div class="home-container23"
                ><span class="home-text50">✔</span
                ><span>Reliability and quality improvement </span></div
              ></div
            ></div
          ><div class="home-pricing-card2 proPricingCard"
            ><div class="home-container24"
              ><span class="home-text51 heading3"
                ><span>Leadership</span><br /></span
              ><span
                >Enabling and build up of high performing teams solving the most
                difficult challenges
              </span></div
            ><div class="home-container25"
              ><span class="home-pro-plan-pricing">Lead</span></div
            ><div class="home-container26"
              ><div class="home-container27"
                ><span class="home-text54">✔</span
                ><span class="bodySmall">Organizational Development</span></div
              ><div class="home-container28"
                ><span class="home-text56">✔</span
                ><span class="bodySmall">Hiring support </span></div
              ><div class="home-container29"
                ><span class="home-text57">✔</span
                ><span class="bodySmall">Interim Leadership roles</span></div
              ><div class="home-container30"
                ><span class="home-text58">✔</span
                ><span>Build high performing teams </span></div
              ><div class="home-container31"
                ><span class="home-text59">✔</span
                ><span class="bodySmall">Restructurings</span></div
              ><div class="home-container32"
                ><span class="home-text60">✔</span
                ><span>Transformation projects </span></div
              ></div
            ></div
          ></div
        ></div
      ></div
    ><div class="home-banner"
      ><div class="bannerContainer home-banner1"
        ><h1 class="home-banner-heading heading2"
          >See my previous achievements and references</h1
        ><span class="home-banner-sub-heading bodySmall"
          ><span
            ><span
              ><span
                >With extensive experience in aerospace and automotive safety
                engineering, I offer top-notch services in functional safety, new
                product introduction, airborne electric motors, batteries, and
                electrical systems. Leading successful engineering teams for
                wireless charging and eVTOL projects sets me apart in the
                industry.</span
              ><span> <span v-html="rawv5zb"></span></span></span
            ><span
              ><span> <span v-html="rawcsh3"></span></span
              ><span> <span v-html="rawlhoe"></span></span></span></span
          ><span
            ><span
              ><span> <span v-html="raw8m0u"></span></span
              ><span> <span v-html="rawcfty"></span></span></span
            ><span
              ><span> <span v-html="rawdaax"></span></span
              ><span> <span v-html="rawn4ex"></span></span></span></span></span
        ><router-link to="/portfolio" class="home-banner-button buttonFilled"
          >Learn More</router-link
        ></div
      ></div
    ><div class="home-faq"
      ><div class="faqContainer"
        ><div class="home-faq1"
          ><div class="home-container33"
            ><span class="overline"><span>FAQ</span><br /></span
            ><h2 class="home-text78 heading2">Common questions</h2
            ><span class="home-text79 bodyLarge"
              ><span>Here are some of the most common questions that we get.</span
              ><br /></span></div
          ><div class="home-container34"
            ><app-question1
              answer="Functional safety ensures that systems operate correctly and safely, reducing the risk of hazards. In aerospace and automotive industries, it is crucial to prevent accidents and ensure the safety of passengers and users."
              question="What is functional safety and why is it important in aerospace and automotive industries?"
            ></app-question1
            ><app-question1
              answer="I have successfully led multiple new product introductions in both aerospace and automotive industries, ensuring compliance with safety standards and delivering innovative solutions to meet client needs."
              question="What experience do you have with new product introduction in the aerospace and automotive sectors?"
              rootClassName="question1-root-class-name"
            ></app-question1
            ><app-question1
              answer="I have played a key role in the design and development of airborne electric motors, batteries, and electrical systems, optimizing performance while maintaining safety standards for use in aircraft and automotive applications."
              question="How have you contributed to the development of airborne electric motors, batteries, and electrical systems?"
            ></app-question1
            ><app-question1
              answer="I have led engineering teams in the successful implementation of wireless charging technology, driving innovation and efficiency in the development of cutting-edge solutions for various industries."
              question="Can you elaborate on your experience in leading engineering teams for wireless charging technology?"
            ></app-question1
            ><app-question1
              answer="I have achieved remarkable success in the field of eVTOL technology by leading projects that have revolutionized urban air mobility, demonstrating expertise in designing safe and efficient aerial transportation solutions."
              question="What achievements have you had in the field of eVTOL (electric vertical takeoff and landing) technology?"
            ></app-question1></div></div></div></div
    ><div class="home-footer"
      ><footer class="footerContainer home-footer1"
        ><div class="home-container35"
          ><span class="logo">KLINGSPOR ENGINEERING</span
          ><nav class="home-nav1"
            ><router-link to="/" class="home-nav121 bodySmall">Home</router-link
            ><router-link to="/about" class="home-nav221 bodySmall"
              >About</router-link
            ><span class="home-nav321 bodySmall">Services</span
            ><router-link to="/portfolio" class="home-nav421 bodySmall"
              >Portfolio</router-link
            ><router-link to="/contact" class="home-nav521 bodySmall"
              >Contact</router-link
            ></nav
          ></div
        ><div class="home-separator"></div
        ><div class="home-container36"
          ><span class="bodySmall home-text82"
            >© 2024 Klingspor Engineering, All Rights Reserved.</span
          ><div class="home-icon-group1"
            ><svg
              viewBox="0 0 950.8571428571428 1024"
              class="home-icon10 socialIcons"
            >
              <path
                d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
              ></path></svg
            ><a
              href="https://www.linkedin.com/in/alexander-klingspor/"
              target="_blank"
              rel="noreferrer noopener"
              class="home-link"
              ><svg viewBox="0 0 1024 1024" class="home-icon12">
                <path
                  d="M928 0h-832c-52.8 0-96 43.2-96 96v832c0 52.8 43.2 96 96 96h832c52.8 0 96-43.2 96-96v-832c0-52.8-43.2-96-96-96zM384 832h-128v-448h128v448zM320 320c-35.4 0-64-28.6-64-64s28.6-64 64-64c35.4 0 64 28.6 64 64s-28.6 64-64 64zM832 832h-128v-256c0-35.4-28.6-64-64-64s-64 28.6-64 64v256h-128v-448h128v79.4c26.4-36.2 66.8-79.4 112-79.4 79.6 0 144 71.6 144 160v288z"
                ></path></svg></a></div></div></footer></div
  ></div>
</template>

<script>
import FeatureCard from '../components/feature-card'
import AppQuestion1 from '../components/question1'

export default {
  name: 'Home',
  props: {},
  components: {
    FeatureCard,
    AppQuestion1,
  },
  data() {
    return {
      rawi7ks: ' ',
      raw54pw: ' ',
      rawxchh: ' ',
      raw00ws: ' ',
      rawmw01: ' ',
      raw3zuq: ' ',
      rawz55g: ' ',
      rawhmod: ' ',
      rawj1fo: ' ',
      rawkxdm: ' ',
      rawjy2a: ' ',
      raw9e9m: ' ',
      rawz6ch: ' ',
      rawsalp: ' ',
      rawv5zb: ' ',
      rawcsh3: ' ',
      rawlhoe: ' ',
      raw8m0u: ' ',
      rawcfty: ' ',
      rawdaax: ' ',
      rawn4ex: ' ',
    }
  },
  metaInfo: {
    title: 'Klingspor Engineering',
    meta: [
      {
        name: 'description',
        content:
          'Description of offered engineering services in aerospace and automotive engineering. Charging, electric propulsion, battery system, aircraft certification',
      },
      {
        property: 'og:title',
        content: 'Klingspor Engineering',
      },
      {
        property: 'og:description',
        content:
          'Description of offered engineering services in aerospace and automotive engineering. Charging, electric propulsion, battery system, aircraft certification',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.home-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-buttons {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-burger-menu {
  display: none;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.home-mobile-menu1 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.home-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.home-nav12 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav22 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav32 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav42 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav52 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 96px;
  align-items: flex-start;
  justify-content: center;
}
.home-container01 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-hero-heading {
  max-width: 800px;
  text-align: center;
}
.home-hero-sub-heading {
  font-size: 18px;
  text-align: center;
}
.home-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-hero-button1 {
  text-decoration: none;
}
.home-hero-button2 {
  text-decoration: none;
}
.home-features {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-features1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
}
.home-container02 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-features-heading {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-features-sub-heading {
  font-size: 18px;
  text-align: center;
}
.home-container03 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-oneandhalfunits);
  grid-template-columns: 1fr 1fr;
}
.home-offerings {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-pricing-sub-heading {
  text-align: center;
}
.home-container05 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-pricing-card {
  height: 612px;
}
.home-container06 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text36 {
  text-transform: uppercase;
}
.home-container07 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-free-plan-price {
  font-size: 4rem;
}
.home-container08 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-container09 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text37 {
  line-height: 150%;
}
.home-container10 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text38 {
  line-height: 150%;
}
.home-container11 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text39 {
  line-height: 150%;
}
.home-container12 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text40 {
  line-height: 150%;
}
.home-container13 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text41 {
  line-height: 150%;
}
.home-container14 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text42 {
  line-height: 150%;
}
.home-pricing-card1 {
  height: 613px;
}
.home-container15 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text43 {
  text-transform: uppercase;
}
.home-container16 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-basic-plan-pricing {
  font-size: 4rem;
}
.home-container17 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-container18 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text44 {
  line-height: 150%;
}
.home-container19 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text46 {
  line-height: 150%;
}
.home-container20 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text47 {
  line-height: 150%;
}
.home-container21 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text48 {
  line-height: 150%;
}
.home-container22 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text49 {
  line-height: 150%;
}
.home-container23 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text50 {
  line-height: 150%;
}
.home-pricing-card2 {
  height: 613px;
}
.home-container24 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text51 {
  text-transform: uppercase;
}
.home-container25 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.home-pro-plan-pricing {
  font-size: 4rem;
}
.home-container26 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-container27 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text54 {
  line-height: 150%;
}
.home-container28 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text56 {
  line-height: 150%;
}
.home-container29 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text57 {
  line-height: 150%;
}
.home-container30 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text58 {
  line-height: 150%;
}
.home-container31 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text59 {
  line-height: 150%;
}
.home-container32 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-text60 {
  line-height: 150%;
}
.home-banner {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-banner-heading {
  text-align: center;
}
.home-banner-sub-heading {
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-banner-button {
  text-decoration: none;
}
.home-faq {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-faq1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
}
.home-container33 {
  display: flex;
  max-width: 35%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text78 {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text79 {
  text-align: left;
}
.home-container34 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container35 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-nav1 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.home-nav121 {
  text-decoration: none;
}
.home-nav221 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-nav321 {
  margin-left: var(--dl-space-space-unit);
}
.home-nav421 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-nav521 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-container36 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon10 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.home-link {
  display: contents;
}
.home-icon12 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .home-hero1 {
    flex-direction: column;
  }
  .home-container01 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .home-hero-heading {
    text-align: center;
  }
  .home-hero-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-features-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-pricing-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-container05 {
    align-items: center;
    flex-direction: column;
  }
  .home-pricing-card {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
  .home-pricing-card1 {
    width: 100%;
    max-width: 450px;
  }
  .home-pricing-card2 {
    width: 100%;
    max-width: 450px;
  }
  .home-banner-sub-heading {
    max-width: 100%;
  }
  .home-text79 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .home-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-desktop-menu {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-nav12 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav22 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav32 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav42 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav52 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-hero-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-features-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container03 {
    grid-template-columns: 1fr;
  }
  .home-pricing-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container05 {
    align-items: center;
    flex-direction: column;
  }
  .home-pricing-card {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-pricing-card1 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-pricing-card2 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-banner1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-banner-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-faq1 {
    flex-direction: column;
  }
  .home-container33 {
    max-width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .home-text79 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container36 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text82 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .home-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu1 {
    padding: 16px;
  }
  .home-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-btn-group {
    flex-direction: column;
  }
  .home-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-pricing-card2 {
    margin-bottom: 0px;
  }
  .home-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container36 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text82 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
</style>
