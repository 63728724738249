<template>
  <div class="services-container"
    ><div class="services-header"
      ><header
        data-thq="thq-navbar"
        class="navbarContainer services-navbar-interactive"
        ><span class="logo">KLINGSPOR Engineering</span
        ><div data-thq="thq-navbar-nav" class="services-desktop-menu"
          ><nav class="services-links"></nav
          ><div class="services-buttons"></div></div
        ><div data-thq="thq-burger-menu" class="services-burger-menu"
          ><svg viewBox="0 0 1024 1024" class="services-icon socialIcons">
            <path
              d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
            ></path></svg></div
        ><div data-thq="thq-mobile-menu" class="services-mobile-menu1 mobileMenu"
          ><div class="services-nav"
            ><div class="services-top"
              ><span class="logo">SAFETYENG</span
              ><div data-thq="thq-close-menu" class="services-close-menu"
                ><svg viewBox="0 0 1024 1024" class="services-icon02 socialIcons">
                  <path
                    d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                  ></path></svg></div></div
            ><nav class="services-links1"
              ><span class="services-nav12 bodySmall">Home</span
              ><span class="services-nav22 bodySmall">About</span
              ><span class="services-nav32 bodySmall">Services</span
              ><span class="services-nav42 bodySmall">Portfolio</span
              ><span class="services-nav52 bodySmall">Contact</span></nav
            ><div class="services-buttons1"
              ><button class="buttonFlat">Login</button
              ><button class="buttonFilled">Register</button></div
            ></div
          ><div
            ><svg
              viewBox="0 0 950.8571428571428 1024"
              class="services-icon04 socialIcons"
            >
              <path
                d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
              ></path></svg
            ><svg
              viewBox="0 0 877.7142857142857 1024"
              class="services-icon06 socialIcons"
            >
              <path
                d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
              ></path></svg
            ><svg
              viewBox="0 0 602.2582857142856 1024"
              class="services-icon08 socialIcons"
            >
              <path
                d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
              ></path></svg></div></div></header></div
    ><div class="services-services"
      ><div class="pricingContainer"
        ><div class="services-container01"
          ><span class="services-pricing-sub-heading bodyLarge"
            ><span
              ><span
                >Unlock the full potential of aerospace and automotive safety
                engineering with our tailored plans</span
              ></span
            ></span
          ><div class="services-container02"
            ><h1 class="services-hero-heading heading1">Services</h1
            ><span class="services-hero-sub-heading bodyLarge"
              ><span
                ><span
                  ><span>Specializing in Safety Engineering Solutions</span
                  ><span> <span v-html="raw4kra"></span></span></span
                ><span
                  ><span> <span v-html="rawlt8s"></span></span
                  ><span> <span v-html="rawg4h8"></span></span></span></span
              ><span
                ><span
                  ><span> <span v-html="raw43nl"></span></span
                  ><span> <span v-html="raw9wcj"></span></span></span
                ><span
                  ><span> <span v-html="rawkwad"></span></span
                  ><span>
                    <span v-html="raw37p0"></span></span></span></span></span
            ><div class="services-btn-group"></div></div></div
        ><div class="services-container03"
          ><div class="services-pricing-card freePricingCard"
            ><div class="services-container04"
              ><span class="services-text16 heading3">Engineering</span
              ><span class="bodySmall"
                >Support with the design of advcanced electrical high-power
                systems</span
              ></div
            ><div class="services-container05"
              ><span class="services-free-plan-price">Design</span></div
            ><div class="services-container06"
              ><div class="services-container07"
                ><span class="services-text17">✔</span
                ><span class="bodySmall">Architecture and concepts</span></div
              ><div class="services-container08"
                ><span class="services-text18">✔</span
                ><span>From requirement to certification</span></div
              ><div class="services-container09"
                ><span class="services-text19">✔</span
                ><span class="bodySmall">Electronics design</span></div
              ><div class="services-container10"
                ><span class="services-text20">✔</span
                ><span class="bodySmall"
                  >Troubleshoot the toughest issues</span
                ></div
              ><div class="services-container11"
                ><span class="services-text21">✔</span
                ><span>Validation &amp; Verification </span></div
              ><div class="services-container12"
                ><span class="services-text22">✔</span><span>DfX </span></div
              ></div
            ></div
          ><div class="services-pricing-card1 basicPricingCard"
            ><div class="services-container13"
              ><span class="services-text23 heading3">Product Development</span
              ><span
                >Prototype development of bespoke electronics and support in
                market launch</span
              ></div
            ><div class="services-container14"
              ><span class="services-basic-plan-pricing">Build</span></div
            ><div class="services-container15"
              ><div class="services-container16"
                ><span class="services-text24">✔</span
                ><span class="bodySmall"
                  >Prototypes and proof of concept</span
                ></div
              ><div class="services-container17"
                ><span class="services-text26">✔</span
                ><span class="bodySmall"
                  >Solve industrialization issues</span
                ></div
              ><div class="services-container18"
                ><span class="services-text27">✔</span
                ><span class="bodySmall">End of line testing</span></div
              ><div class="services-container19"
                ><span class="services-text28">✔</span
                ><span class="bodySmall">Series production preparation</span></div
              ><div class="services-container20"
                ><span class="services-text29">✔</span
                ><span>Get from concept to market</span></div
              ><div class="services-container21"
                ><span class="services-text30">✔</span
                ><span>Reliability and quality improvement </span></div
              ></div
            ></div
          ><div class="services-pricing-card2 proPricingCard"
            ><div class="services-container22"
              ><span class="services-text31 heading3"
                ><span>Leadership</span><br /></span
              ><span
                >Enabling and build up of high performing teams solving the most
                difficult challenges
              </span></div
            ><div class="services-container23"
              ><span class="services-pro-plan-pricing">Lead</span></div
            ><div class="services-container24"
              ><div class="services-container25"
                ><span class="services-text34">✔</span
                ><span class="bodySmall">Organizational Development</span></div
              ><div class="services-container26"
                ><span class="services-text36">✔</span
                ><span class="bodySmall">Hiring support </span></div
              ><div class="services-container27"
                ><span class="services-text37">✔</span
                ><span class="bodySmall">Interim Leadership roles</span></div
              ><div class="services-container28"
                ><span class="services-text38">✔</span
                ><span>Build high performing teams </span></div
              ><div class="services-container29"
                ><span class="services-text39">✔</span
                ><span class="bodySmall">Restructurings</span></div
              ><div class="services-container30"
                ><span class="services-text40">✔</span
                ><span>Transformation projects </span></div
              ></div
            ></div
          ></div
        ></div
      ></div
    ><div class="services-footer"
      ><footer class="footerContainer services-footer1"
        ><div class="services-container31"
          ><span class="logo">KLINGSPOR ENGINEERING</span
          ><nav class="services-nav1"
            ><router-link to="/" class="services-nav121 bodySmall"
              >Home</router-link
            ><router-link to="/about" class="services-nav221 bodySmall"
              >About</router-link
            ><router-link to="/services" class="services-nav321 bodySmall"
              >Services</router-link
            ><router-link to="/portfolio" class="services-nav421 bodySmall"
              >Portfolio</router-link
            ><router-link to="/contact" class="services-nav521 bodySmall"
              >Contact</router-link
            ></nav
          ></div
        ><div class="services-separator"></div
        ><div class="services-container32"
          ><span class="bodySmall services-text41"
            >© 2024 Klingspor Engineering, All Rights Reserved.</span
          ><div class="services-icon-group1"
            ><svg
              viewBox="0 0 950.8571428571428 1024"
              class="services-icon10 socialIcons"
            >
              <path
                d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
              ></path></svg
            ><a
              href="https://www.linkedin.com/in/alexander-klingspor/"
              target="_blank"
              rel="noreferrer noopener"
              class="services-link"
              ><svg viewBox="0 0 1024 1024" class="services-icon12">
                <path
                  d="M928 0h-832c-52.8 0-96 43.2-96 96v832c0 52.8 43.2 96 96 96h832c52.8 0 96-43.2 96-96v-832c0-52.8-43.2-96-96-96zM384 832h-128v-448h128v448zM320 320c-35.4 0-64-28.6-64-64s28.6-64 64-64c35.4 0 64 28.6 64 64s-28.6 64-64 64zM832 832h-128v-256c0-35.4-28.6-64-64-64s-64 28.6-64 64v256h-128v-448h128v79.4c26.4-36.2 66.8-79.4 112-79.4 79.6 0 144 71.6 144 160v288z"
                ></path></svg></a></div></div></footer></div
  ></div>
</template>

<script>
export default {
  name: 'Services',
  props: {},
  data() {
    return {
      raw4kra: ' ',
      rawlt8s: ' ',
      rawg4h8: ' ',
      raw43nl: ' ',
      raw9wcj: ' ',
      rawkwad: ' ',
      raw37p0: ' ',
    }
  },
  metaInfo: {
    title: 'Services - Klingspor Engineering',
    meta: [
      {
        name: 'description',
        content:
          'Description of offered engineering services in aerospace and automotive engineering. Charging, electric propulsion, battery system, aircraft certification',
      },
      {
        property: 'og:title',
        content: 'Services - Klingspor Engineering',
      },
      {
        property: 'og:description',
        content:
          'Description of offered engineering services in aerospace and automotive engineering. Charging, electric propulsion, battery system, aircraft certification',
      },
    ],
  },
}
</script>

<style scoped>
.services-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-custom-neutral-dark);
}
.services-header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.services-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.services-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.services-buttons {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.services-burger-menu {
  display: none;
}
.services-icon {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.services-mobile-menu1 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
}
.services-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.services-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.services-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.services-icon02 {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.services-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.services-nav12 {
  margin-bottom: var(--dl-space-space-unit);
}
.services-nav22 {
  margin-bottom: var(--dl-space-space-unit);
}
.services-nav32 {
  margin-bottom: var(--dl-space-space-unit);
}
.services-nav42 {
  margin-bottom: var(--dl-space-space-unit);
}
.services-nav52 {
  margin-bottom: var(--dl-space-space-unit);
}
.services-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.services-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.services-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.services-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.services-services {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.services-container01 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.services-pricing-sub-heading {
  text-align: center;
}
.services-container02 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.services-hero-heading {
  max-width: 800px;
  text-align: center;
}
.services-hero-sub-heading {
  font-size: 18px;
  text-align: center;
}
.services-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.services-container03 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.services-pricing-card {
  height: 612px;
}
.services-container04 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.services-text16 {
  text-transform: uppercase;
}
.services-container05 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.services-free-plan-price {
  font-size: 4rem;
}
.services-container06 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.services-container07 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.services-text17 {
  line-height: 150%;
}
.services-container08 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.services-text18 {
  line-height: 150%;
}
.services-container09 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.services-text19 {
  line-height: 150%;
}
.services-container10 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.services-text20 {
  line-height: 150%;
}
.services-container11 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.services-text21 {
  line-height: 150%;
}
.services-container12 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.services-text22 {
  line-height: 150%;
}
.services-pricing-card1 {
  height: 613px;
}
.services-container13 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.services-text23 {
  text-transform: uppercase;
}
.services-container14 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.services-basic-plan-pricing {
  font-size: 4rem;
}
.services-container15 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.services-container16 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.services-text24 {
  line-height: 150%;
}
.services-container17 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.services-text26 {
  line-height: 150%;
}
.services-container18 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.services-text27 {
  line-height: 150%;
}
.services-container19 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.services-text28 {
  line-height: 150%;
}
.services-container20 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.services-text29 {
  line-height: 150%;
}
.services-container21 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.services-text30 {
  line-height: 150%;
}
.services-pricing-card2 {
  height: 613px;
}
.services-container22 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.services-text31 {
  text-transform: uppercase;
}
.services-container23 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
}
.services-pro-plan-pricing {
  font-size: 4rem;
}
.services-container24 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.services-container25 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.services-text34 {
  line-height: 150%;
}
.services-container26 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.services-text36 {
  line-height: 150%;
}
.services-container27 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.services-text37 {
  line-height: 150%;
}
.services-container28 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.services-text38 {
  line-height: 150%;
}
.services-container29 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.services-text39 {
  line-height: 150%;
}
.services-container30 {
  gap: 4px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.services-text40 {
  line-height: 150%;
}
.services-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.services-container31 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.services-nav1 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.services-nav121 {
  text-decoration: none;
}
.services-nav221 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.services-nav321 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.services-nav421 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.services-nav521 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.services-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.services-container32 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.services-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.services-icon10 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.services-link {
  display: contents;
}
.services-icon12 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .services-pricing-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .services-container02 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .services-hero-heading {
    text-align: center;
  }
  .services-hero-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .services-container03 {
    align-items: center;
    flex-direction: column;
  }
  .services-pricing-card {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    max-width: 450px;
  }
  .services-pricing-card1 {
    width: 100%;
    max-width: 450px;
  }
  .services-pricing-card2 {
    width: 100%;
    max-width: 450px;
  }
}
@media(max-width: 767px) {
  .services-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .services-desktop-menu {
    display: none;
  }
  .services-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .services-nav12 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .services-nav22 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .services-nav32 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .services-nav42 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .services-nav52 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .services-pricing-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .services-hero-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .services-container03 {
    align-items: center;
    flex-direction: column;
  }
  .services-pricing-card {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .services-pricing-card1 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .services-pricing-card2 {
    width: 100%;
    max-width: 450px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .services-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .services-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .services-container32 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .services-text41 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .services-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .services-mobile-menu1 {
    padding: 16px;
  }
  .services-container02 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .services-btn-group {
    flex-direction: column;
  }
  .services-pricing-card2 {
    margin-bottom: 0px;
  }
  .services-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .services-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .services-container32 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .services-text41 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
</style>
