<template>
  <div class="portfolio-container"
    ><div class="portfolio-header"
      ><header
        data-thq="thq-navbar"
        class="navbarContainer portfolio-navbar-interactive"
        ><span class="logo">KLINGSPOR Engineering</span
        ><div data-thq="thq-navbar-nav" class="portfolio-desktop-menu"
          ><nav class="portfolio-links"></nav
          ><div class="portfolio-buttons"></div></div
        ><div data-thq="thq-burger-menu" class="portfolio-burger-menu"
          ><svg viewBox="0 0 1024 1024" class="portfolio-icon socialIcons">
            <path
              d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
            ></path></svg></div
        ><div data-thq="thq-mobile-menu" class="portfolio-mobile-menu1 mobileMenu"
          ><div class="portfolio-nav"
            ><div class="portfolio-top"
              ><span class="logo">SAFETYENG</span
              ><div data-thq="thq-close-menu" class="portfolio-close-menu"
                ><svg
                  viewBox="0 0 1024 1024"
                  class="portfolio-icon02 socialIcons"
                >
                  <path
                    d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                  ></path></svg></div></div
            ><nav class="portfolio-links1"
              ><span class="portfolio-nav12 bodySmall">Home</span
              ><span class="portfolio-nav22 bodySmall">About</span
              ><span class="portfolio-nav32 bodySmall">Services</span
              ><span class="portfolio-nav42 bodySmall">Portfolio</span
              ><span class="portfolio-nav52 bodySmall">Contact</span></nav
            ><div class="portfolio-buttons1"
              ><button class="buttonFlat">Login</button
              ><button class="buttonFilled">Register</button></div
            ></div
          ><div
            ><svg
              viewBox="0 0 950.8571428571428 1024"
              class="portfolio-icon04 socialIcons"
            >
              <path
                d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
              ></path></svg
            ><svg
              viewBox="0 0 877.7142857142857 1024"
              class="portfolio-icon06 socialIcons"
            >
              <path
                d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
              ></path></svg
            ><svg
              viewBox="0 0 602.2582857142856 1024"
              class="portfolio-icon08 socialIcons"
            >
              <path
                d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
              ></path></svg></div></div></header></div
    ><div class="portfolio-hero"
      ><div class="heroContainer portfolio-hero1"
        ><div class="portfolio-container01"
          ><h1 class="portfolio-hero-heading heading1">Portfolio</h1
          ><router-link to="/contact" class="portfolio-hero-button1 buttonFilled"
            >Contact Me</router-link
          ><span class="portfolio-hero-sub-heading bodyLarge"
            ><span
              ><span
                ><span>Specializing in Safety Engineering Solutions</span
                ><span> <span v-html="rawj23i"></span></span></span
              ><span
                ><span> <span v-html="rawab4q"></span></span
                ><span> <span v-html="rawsbcj"></span></span></span></span
            ><span
              ><span
                ><span> <span v-html="rawo12a"></span></span
                ><span> <span v-html="raw15ng"></span></span></span
              ><span
                ><span> <span v-html="rawrpf5"></span></span
                ><span>
                  <span
                    v-html="raw3xjo"
                  ></span></span></span></span></span></div></div></div
    ><div class="portfolio-btn-group"></div
    ><div class="portfolio-gallery"
      ><div class="portfolio-gallery1"
        ><div class="portfolio-container02"
          ><div class="portfolio-container03"></div></div
        ><div class="portfolio-container04"
          ><div class="portfolio-container05"></div></div
        ><div class="portfolio-container06"></div
        ><div class="portfolio-container07"></div
        ><div class="portfolio-container08"></div
        ><div class="portfolio-container09"></div></div></div
    ><footer class="footerContainer portfolio-footer"
      ><div class="portfolio-container10"
        ><span class="logo">KLINGSPOR ENGINEERING</span
        ><nav class="portfolio-nav1"
          ><router-link to="/" class="portfolio-nav121 bodySmall"
            >Home</router-link
          ><router-link to="/about" class="portfolio-nav221 bodySmall"
            >About</router-link
          ><router-link to="/services" class="portfolio-nav321 bodySmall"
            >Services</router-link
          ><router-link to="/portfolio" class="portfolio-nav421 bodySmall"
            >Portfolio</router-link
          ><router-link to="/contact" class="portfolio-nav521 bodySmall"
            >Contact</router-link
          ></nav
        ></div
      ><div class="portfolio-separator"></div
      ><div class="portfolio-container11"
        ><span class="bodySmall portfolio-text14"
          >© 2024 Klingspor Engineering, All Rights Reserved.</span
        ><div class="portfolio-icon-group1"
          ><svg
            viewBox="0 0 950.8571428571428 1024"
            class="portfolio-icon10 socialIcons"
          >
            <path
              d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
            ></path></svg
          ><a
            href="https://www.linkedin.com/in/alexander-klingspor/"
            target="_blank"
            rel="noreferrer noopener"
            class="portfolio-link"
            ><svg viewBox="0 0 1024 1024" class="portfolio-icon12">
              <path
                d="M928 0h-832c-52.8 0-96 43.2-96 96v832c0 52.8 43.2 96 96 96h832c52.8 0 96-43.2 96-96v-832c0-52.8-43.2-96-96-96zM384 832h-128v-448h128v448zM320 320c-35.4 0-64-28.6-64-64s28.6-64 64-64c35.4 0 64 28.6 64 64s-28.6 64-64 64zM832 832h-128v-256c0-35.4-28.6-64-64-64s-64 28.6-64 64v256h-128v-448h128v79.4c26.4-36.2 66.8-79.4 112-79.4 79.6 0 144 71.6 144 160v288z"
              ></path></svg></a></div></div></footer
  ></div>
</template>

<script>
export default {
  name: 'Portfolio',
  props: {},
  data() {
    return {
      rawj23i: ' ',
      rawab4q: ' ',
      rawsbcj: ' ',
      rawo12a: ' ',
      raw15ng: ' ',
      rawrpf5: ' ',
      raw3xjo: ' ',
    }
  },
  metaInfo: {
    title: 'Portfolio - Klingspor Engineering',
    meta: [
      {
        name: 'description',
        content:
          'Description of offered engineering services in aerospace and automotive engineering. Charging, electric propulsion, battery system, aircraft certification',
      },
      {
        property: 'og:title',
        content: 'Portfolio - Klingspor Engineering',
      },
      {
        property: 'og:description',
        content:
          'Description of offered engineering services in aerospace and automotive engineering. Charging, electric propulsion, battery system, aircraft certification',
      },
    ],
  },
}
</script>

<style scoped>
.portfolio-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-custom-neutral-dark);
}
.portfolio-header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.portfolio-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.portfolio-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.portfolio-buttons {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.portfolio-burger-menu {
  display: none;
}
.portfolio-icon {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.portfolio-mobile-menu1 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
}
.portfolio-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.portfolio-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.portfolio-icon02 {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.portfolio-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-nav12 {
  margin-bottom: var(--dl-space-space-unit);
}
.portfolio-nav22 {
  margin-bottom: var(--dl-space-space-unit);
}
.portfolio-nav32 {
  margin-bottom: var(--dl-space-space-unit);
}
.portfolio-nav42 {
  margin-bottom: var(--dl-space-space-unit);
}
.portfolio-nav52 {
  margin-bottom: var(--dl-space-space-unit);
}
.portfolio-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.portfolio-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.portfolio-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.portfolio-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.portfolio-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.portfolio-container01 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 528px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.portfolio-hero-heading {
  max-width: 800px;
  text-align: center;
}
.portfolio-hero-button1 {
  text-decoration: none;
}
.portfolio-hero-sub-heading {
  font-size: 18px;
  text-align: center;
}
.portfolio-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.portfolio-gallery {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.portfolio-gallery1 {
  width: 100%;
  height: 1200px;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-custom-neutral-dark);
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "a b b" "a b b" "a c d" "a c d"  "e e d" "e e f" "e e f";
  grid-template-columns: 1fr 1fr 1fr;
}
.portfolio-container02 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 1;
  grid-area: a;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1457364847821-58861bbda116?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNDA3OTU5M3w&ixlib=rb-4.0.3&q=80&w=1080");
  background-position: center;
}
.portfolio-container02:hover {
  transform: scale(0.98);
}
.portfolio-container03 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1635335874521-7987db781153?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDF8fGNvbnRhY3RvcnxlbnwwfHx8fDE3MTQwNzk4MzB8MA&ixlib=rb-4.0.3&w=1400");
}
.portfolio-container04 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: b;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1709125559817-f09e090488d7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcxNDA3OTU5NHw&ixlib=rb-4.0.3&q=80&w=1080");
  background-position: center;
}
.portfolio-container04:hover {
  transform: scale(0.98);
}
.portfolio-container05 {
  width: 100%;
  height: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1617886322168-72b886573c35?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDQ4fHx3aXJlbGVzcyUyMGNoYXJnaW5nfGVufDB8fHx8MTcxNDA3OTg1M3ww&ixlib=rb-4.0.3&w=1400");
}
.portfolio-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: c;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1600428235269-c326df6361fe?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDQzfHxiYXR0ZXJpZXN8ZW58MHx8fHwxNzE0MDc5Nzc2fDA&ixlib=rb-4.0.3&w=1400");
  background-position: center;
}
.portfolio-container06:hover {
  transform: scale(0.98);
}
.portfolio-container07 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: d;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1593941707874-ef25b8b4a92b?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDMzfHxlbGVjdHJpYyUyMG1vdG9yfGVufDB8fHx8MTcxNDA3OTcxMnww&ixlib=rb-4.0.3&w=1400");
  background-position: center;
}
.portfolio-container07:hover {
  transform: scale(0.98);
}
.portfolio-container08 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: e;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1551192335-592eaff28647?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIxfHxiYXR0ZXJpZXN8ZW58MHx8fHwxNzE0MDc5NzcwfDA&ixlib=rb-4.0.3&w=1400");
  background-position: center;
}
.portfolio-container08:hover {
  transform: scale(0.98);
}
.portfolio-container09 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: f;
  transition: 0.3s;
  align-items: flex-start;
  flex-direction: column;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1595279211419-88239fbff506?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDYxfHxlbGVjdHJpYyUyMGFpcmNyYWZ0fGVufDB8fHx8MTcxNDA3OTc0OHww&ixlib=rb-4.0.3&w=1400");
  background-position: center;
}
.portfolio-container09:hover {
  transform: scale(0.98);
}
.portfolio-container10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.portfolio-nav1 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.portfolio-nav121 {
  text-decoration: none;
}
.portfolio-nav221 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.portfolio-nav321 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.portfolio-nav421 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.portfolio-nav521 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.portfolio-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.portfolio-container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.portfolio-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.portfolio-icon10 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.portfolio-link {
  display: contents;
}
.portfolio-icon12 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .portfolio-hero1 {
    flex-direction: column;
  }
  .portfolio-container01 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .portfolio-hero-heading {
    text-align: center;
  }
  .portfolio-hero-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .portfolio-gallery1 {
    grid-gap: var(--dl-space-space-unit);
  }
}
@media(max-width: 767px) {
  .portfolio-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .portfolio-desktop-menu {
    display: none;
  }
  .portfolio-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .portfolio-nav12 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .portfolio-nav22 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .portfolio-nav32 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .portfolio-nav42 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .portfolio-nav52 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .portfolio-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .portfolio-hero-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .portfolio-gallery1 {
    height: auto;
    display: flex;
    padding: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .portfolio-container03 {
    height: 220px;
  }
  .portfolio-container05 {
    height: 220px;
  }
  .portfolio-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .portfolio-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .portfolio-container11 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .portfolio-text14 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .portfolio-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .portfolio-mobile-menu1 {
    padding: 16px;
  }
  .portfolio-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .portfolio-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .portfolio-btn-group {
    flex-direction: column;
  }
  .portfolio-gallery1 {
    width: 100%;
    padding: var(--dl-space-space-unit);
  }
  .portfolio-footer {
    padding: var(--dl-space-space-unit);
  }
  .portfolio-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .portfolio-container11 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .portfolio-text14 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
</style>
