<template>
  <div class="about-container"
    ><div class="about-header"
      ><header
        data-thq="thq-navbar"
        class="navbarContainer about-navbar-interactive"
        ><span class="logo">KLINGSPOR Engineering</span
        ><div data-thq="thq-navbar-nav" class="about-desktop-menu"
          ><nav class="about-links"></nav><div class="about-buttons"></div></div
        ><div data-thq="thq-burger-menu" class="about-burger-menu"
          ><svg viewBox="0 0 1024 1024" class="about-icon socialIcons">
            <path
              d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
            ></path></svg></div
        ><div data-thq="thq-mobile-menu" class="about-mobile-menu1 mobileMenu"
          ><div class="about-nav"
            ><div class="about-top"
              ><span class="logo">SAFETYENG</span
              ><div data-thq="thq-close-menu" class="about-close-menu"
                ><svg viewBox="0 0 1024 1024" class="about-icon02 socialIcons">
                  <path
                    d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                  ></path></svg></div></div
            ><nav class="about-links1"
              ><span class="about-nav12 bodySmall">Home</span
              ><span class="about-nav22 bodySmall">About</span
              ><span class="about-nav32 bodySmall">Services</span
              ><span class="about-nav42 bodySmall">Portfolio</span
              ><span class="about-nav52 bodySmall">Contact</span></nav
            ><div class="about-buttons1"
              ><button class="buttonFlat">Login</button
              ><button class="buttonFilled">Register</button></div
            ></div
          ><div
            ><svg
              viewBox="0 0 950.8571428571428 1024"
              class="about-icon04 socialIcons"
            >
              <path
                d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
              ></path></svg
            ><svg
              viewBox="0 0 877.7142857142857 1024"
              class="about-icon06 socialIcons"
            >
              <path
                d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
              ></path></svg
            ><svg
              viewBox="0 0 602.2582857142856 1024"
              class="about-icon08 socialIcons"
            >
              <path
                d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
              ></path></svg></div></div></header></div
    ><div class="about-content"
      ><div class="pricingContainer"
        ><div class="about-container1"
          ><span class="about-pricing-sub-heading bodyLarge"
            ><span
              ><span
                >Unlock the full potential of aerospace and automotive safety
                engineering with our tailored plans</span
              ></span
            ></span
          ><div class="about-container2"
            ><h1 class="about-hero-heading heading1">About</h1
            ><span class="about-hero-sub-heading bodyLarge"
              ><span
                ><span
                  ><span>Specializing in Safety Engineering Solutions</span
                  ><span> <span v-html="raw5f66"></span></span></span
                ><span
                  ><span> <span v-html="rawpx2z"></span></span
                  ><span> <span v-html="rawkej0"></span></span></span></span
              ><span
                ><span
                  ><span> <span v-html="rawf2fk"></span></span
                  ><span> <span v-html="rawkg8y"></span></span></span
                ><span
                  ><span> <span v-html="raw4un8"></span></span
                  ><span>
                    <span v-html="rawmfjy"></span></span></span></span></span
            ><div class="about-btn-group"></div></div></div
        ><span
          ><span>Klingspor Engineering</span><br /><br /><span
            >Alexander Klingspor</span
          ><br /><span>Hauptstraße 157A</span><br /><span>76297 Stutensee</span
          ><br /><br /><span>Tel: <span v-html="rawaoz4"></span></span
          ><a href="tel:+49 7244 6088969" class="about-link">+49 7244 6088969</a
          ><br /><br /><a href="mailto:info@klingspor.one?subject=Request"
            >info@klingspor.one</a
          ><br /><br /><br /><span class="about-text33"
            >Disclaimer - rechtliche Hinweise</span
          ><br /><br /><span class="about-text36"
            >Auskunfts- und Widerrufsrecht</span
          ><br /><br /><span
            >Sie haben jederzeit das Recht, sich unentgeltlich und unverzüglich
            über die zu Ihrer Person erhobenen Daten zu erkundigen. Ebenfalls
            können Sie Ihre Zustimmung zur Verwendung Ihrer angegebenen
            persönlichen Daten mit Wirkung für die Zukunft widerrufen. Hierfür
            wenden Sie sich bitte an den im Impressum angegebenen
            Diensteanbieter.</span
          ><br /><br /><br /><span class="about-text43"
            >Datenschutz (allgemein)</span
          ><br /><br /><span
            >Beim Zugriff auf unsere Webseite werden automatisch allgemeine
            Informationen (sog. Server-Logfiles) erfasst. Diese beinhalten u.a.
            den von Ihnen verwendeten Webbrowser sowie Ihr Betriebssystem und
            Ihren Internet Service Provider. Diese Daten lassen keinerlei
            Rückschlüsse auf Ihre Person zu und werden von uns statistisch
            ausgewertet, um unseren Internetauftritt technisch und inhaltlich zu
            verbessern. Das Erfassen dieser Informationen ist notwendig, um den
            Inhalt der Webseite korrekt ausliefern zu können.</span
          ><br /><br /><span
            >Die Nutzung der Webseite ist grundsätzlich ohne Angabe
            personenbezogener Daten möglich. Soweit personenbezogene Daten
            (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden,
            erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese
            Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte
            weitergegeben.</span
          ><br /><br /><span
            >Sofern ein Vertragsverhältnis begründet, inhaltlich ausgestaltet oder
            geändert werden soll oder Sie an uns eine Anfrage stellen, erheben und
            verwenden wir personenbezogene Daten von Ihnen, soweit dies zu diesem
            Zwecke erforderlich ist (Bestandsdaten). Wir erheben, verarbeiten und
            nutzen personenbezogene Daten soweit dies erforderlich ist, um Ihnen
            die Inanspruchnahme des Webangebots zu ermöglichen (Nutzungsdaten).
            Sämtliche personenbezogenen Daten werden nur solange gespeichert wie
            dies für den genannten Zweck (Bearbeitung Ihrer Anfrage oder
            Abwicklung eines Vertrags) erforderlich ist. Hierbei werden steuer-
            und handelsrechtliche Aufbewahrungsfristen von uns berücksichtigt. Auf
            Anordnung der zuständigen Stellen müssen wir im Einzelfall Auskunft
            über diese Daten (Bestandsdaten) erteilen, soweit dies für Zwecke der
            Strafverfolgung, zur Gefahrenabwehr, zur Erfüllung der gesetzlichen
            Aufgaben der Verfassungsschutzbehörden oder des Militärischen
            Abschirmdienstes oder zur Durchsetzung der Rechte am geistigen
            Eigentum erforderlich ist.</span
          ><br /><br /><span
            >Wir weisen ausdrücklich darauf hin, dass die Datenübertragung im
            Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken
            aufweisen kann. Vor dem Zugriff auf Daten kann nicht lückenlos
            geschützt werden.</span
          ><br /><br /><span
            >Die Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
            angeforderter Werbung und Informationsmaterialien wird hiermit
            ausdrücklich untersagt. Ausgenommen hiervon sind bestehende
            Geschäftsbeziehungen bzw. es liegt Ihnen eine entsprechende
            Einwilligung von uns vor.</span
          ><br /><br /><span
            >Die Anbieter und alle auf dieser Website genannten Dritten behalten
            sich ausdrücklich rechtliche Schritte im Falle der unverlangten
            Zusendung von Werbeinformationen vor. Gleiches gilt für die
            kommerzielle Verwendung und Weitergabe der Daten.</span
          ><br /><br /><br /><br /><span class="about-text66"
            >Disclaimer (Haftungsausschluss)</span
          ><br class="about-text67" /><br /><span class="about-text69"
            >1. Haftung für Inhalte</span
          ><br class="about-text70" /><br /><span
            >Als Diensteanbieter sind wir gemäß § 7 Abs. 1 TMG für eigene Inhalte
            auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
            §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
            überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
            Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
            Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
            hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.</span
          ><br /><br /><br /><span class="about-text76">2. Haftung für Links</span
          ><br class="about-text77" /><br /><span
            >Diese Website enthält Links zu externen Webseiten Dritter, auf deren
            Inhalte kein Einfluss genommen werden kann. Deshalb kann für diese
            fremden Inhalte auch keine Gewähr übernommen werden. Für die Inhalte
            der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt
            der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
            Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
            permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
            konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
            Bekanntwerden von Rechtsverletzungen werden derartige Links umgehend
            von dieser Website auf die rechtsverletzende Site entfernen.</span
          ><br /><br /><br /><span class="about-text83">3. Urheberrecht</span
          ><br class="about-text84" /><br /><span
            >Die durch die Diensteanbieter, deren Mitarbeiter und beauftragte
            Dritte erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
            deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung
            und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
            bedürfen der vorherigen schriftlichen Zustimmung des jeweiligen Autors
            bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
            privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte
            auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
            Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter
            als solche gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
            werden derartige Inhalte umgehend entfernen.</span
          ><br /><br /><span>---</span><br /><br class="about-text91" /><span
            class="about-text92"
            >Dieses Impressum wurde mit Hilfe des
            <span v-html="rawdqw9"></span></span
          ><a
            href="http://www.hensche.de/impressum-generator.html"
            target="_blank"
            rel="noreferrer noopener"
            class="about-link2"
            >Impressum-Generators</a
          ><span class="about-text93"> von <span v-html="raw4kks"></span></span
          ><a
            href="http://www.hensche.de/Rechtsanwalt_Arbeitsrecht_Stuttgart.html"
            target="_blank"
            rel="noreferrer noopener"
            class="about-link3"
            >HENSCHE Rechtsanwälte, Stuttgart</a
          ><span class="about-text94"> erstellt.</span></span
        ></div
      ></div
    ><div class="about-footer"
      ><footer class="footerContainer about-footer1"
        ><div class="about-container3"
          ><span class="logo">KLINGSPOR ENGINEERING</span
          ><nav class="about-nav1"
            ><router-link to="/" class="about-nav121 bodySmall">Home</router-link
            ><router-link to="/about" class="about-nav221 bodySmall"
              >About</router-link
            ><router-link to="/services" class="about-nav321 bodySmall"
              >Services</router-link
            ><router-link to="/portfolio" class="about-nav421 bodySmall"
              >Portfolio</router-link
            ><router-link to="/contact" class="about-nav521 bodySmall"
              >Contact</router-link
            ></nav
          ></div
        ><div class="about-separator"></div
        ><div class="about-container4"
          ><span class="bodySmall about-text95"
            >© 2024 Klingspor Engineering, All Rights Reserved.</span
          ><div class="about-icon-group1"
            ><svg
              viewBox="0 0 950.8571428571428 1024"
              class="about-icon10 socialIcons"
            >
              <path
                d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
              ></path></svg
            ><a
              href="https://www.linkedin.com/in/alexander-klingspor/"
              target="_blank"
              rel="noreferrer noopener"
              class="about-link4"
              ><svg viewBox="0 0 1024 1024" class="about-icon12">
                <path
                  d="M928 0h-832c-52.8 0-96 43.2-96 96v832c0 52.8 43.2 96 96 96h832c52.8 0 96-43.2 96-96v-832c0-52.8-43.2-96-96-96zM384 832h-128v-448h128v448zM320 320c-35.4 0-64-28.6-64-64s28.6-64 64-64c35.4 0 64 28.6 64 64s-28.6 64-64 64zM832 832h-128v-256c0-35.4-28.6-64-64-64s-64 28.6-64 64v256h-128v-448h128v79.4c26.4-36.2 66.8-79.4 112-79.4 79.6 0 144 71.6 144 160v288z"
                ></path></svg></a></div></div></footer></div
  ></div>
</template>

<script>
export default {
  name: 'About',
  props: {},
  data() {
    return {
      raw5f66: ' ',
      rawpx2z: ' ',
      rawkej0: ' ',
      rawf2fk: ' ',
      rawkg8y: ' ',
      raw4un8: ' ',
      rawmfjy: ' ',
      rawaoz4: ' ',
      rawdqw9: ' ',
      raw4kks: ' ',
    }
  },
  metaInfo: {
    title: 'About - Klingspor Engineering',
    meta: [
      {
        name: 'description',
        content:
          'Description of offered engineering services in aerospace and automotive engineering. Charging, electric propulsion, battery system, aircraft certification',
      },
      {
        property: 'og:title',
        content: 'About - Klingspor Engineering',
      },
      {
        property: 'og:description',
        content:
          'Description of offered engineering services in aerospace and automotive engineering. Charging, electric propulsion, battery system, aircraft certification',
      },
    ],
  },
}
</script>

<style scoped>
.about-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.about-header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.about-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.about-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.about-buttons {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.about-burger-menu {
  display: none;
}
.about-icon {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.about-mobile-menu1 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
}
.about-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.about-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.about-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-icon02 {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.about-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.about-nav12 {
  margin-bottom: var(--dl-space-space-unit);
}
.about-nav22 {
  margin-bottom: var(--dl-space-space-unit);
}
.about-nav32 {
  margin-bottom: var(--dl-space-space-unit);
}
.about-nav42 {
  margin-bottom: var(--dl-space-space-unit);
}
.about-nav52 {
  margin-bottom: var(--dl-space-space-unit);
}
.about-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.about-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.about-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.about-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.about-content {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-container1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.about-pricing-sub-heading {
  text-align: center;
}
.about-container2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.about-hero-heading {
  max-width: 800px;
  text-align: center;
}
.about-hero-sub-heading {
  font-size: 18px;
  text-align: center;
}
.about-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.about-link {
  text-decoration: underline;
}
.about-text33 {
  font-weight: 700;
}
.about-text36 {
  font-style: normal;
  font-weight: 700;
}
.about-text43 {
  font-style: normal;
  font-weight: 700;
}
.about-text66 {
  font-style: normal;
  font-weight: 700;
}
.about-text67 {
  font-style: normal;
  font-weight: 700;
}
.about-text69 {
  font-style: normal;
  font-weight: 700;
}
.about-text70 {
  font-style: normal;
  font-weight: 700;
}
.about-text76 {
  font-style: normal;
  font-weight: 700;
}
.about-text77 {
  font-style: normal;
  font-weight: 700;
}
.about-text83 {
  font-style: normal;
  font-weight: 700;
}
.about-text84 {
  font-style: normal;
  font-weight: 700;
}
.about-text91 {
  font-style: normal;
  font-weight: 200;
}
.about-text92 {
  font-style: normal;
  font-weight: 200;
}
.about-link2 {
  font-style: normal;
  font-weight: 200;
  text-decoration: underline;
}
.about-text93 {
  font-style: normal;
  font-weight: 200;
}
.about-link3 {
  font-style: normal;
  font-weight: 200;
  text-decoration: underline;
}
.about-text94 {
  font-style: normal;
  font-weight: 200;
}
.about-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-container3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.about-nav1 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.about-nav121 {
  text-decoration: none;
}
.about-nav221 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.about-nav321 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.about-nav421 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.about-nav521 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.about-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.about-container4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.about-icon-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.about-icon10 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-twounits);
}
.about-link4 {
  display: contents;
}
.about-icon12 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .about-pricing-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .about-container2 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .about-hero-heading {
    text-align: center;
  }
  .about-hero-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .about-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .about-desktop-menu {
    display: none;
  }
  .about-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .about-nav12 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-nav22 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-nav32 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-nav42 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-nav52 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-pricing-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .about-hero-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .about-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .about-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .about-container4 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .about-text95 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .about-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .about-mobile-menu1 {
    padding: 16px;
  }
  .about-container2 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .about-btn-group {
    flex-direction: column;
  }
  .about-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .about-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .about-container4 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .about-text95 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
</style>
